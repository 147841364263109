<template>
  <v-main>
    <h2 class="mt-2 mb-8 titleColor--text">Usuários</h2>

    <v-card>
      <v-card-title> </v-card-title>
      <v-data-table
        :headers="headers"
        :items="users"
        :loading="loading"
        :footer-props="{
          'items-per-page-options': [10, 25, 50],
        }"
        sort-by="empresa"
        :sort-desc="false"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat color="backgroundSecond">
            <v-spacer></v-spacer>
            <v-dialog v-model="dialogUsuario" max-width="500px" persistent>
              <template v-slot:activator="{ on, attrs }">
                <button
                  id="button_add_user"
                  v-bind="attrs"
                  type="button"
                  class="mb-2 v-btn v-size--default primary"
                  role="button"
                  aria-haspopup="true"
                  dense
                  aria-expanded="false"
                  style="z-index: 203 !important"
                  v-on="on"
                >
                  <span class="v-btn__content"> Adicionar usuário </span>
                </button>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5" style="color: #182026">Adicionar usuário</span>
                </v-card-title>

                <v-card-text class="pa-4">
                  <v-container>
                    <v-form ref="form" v-model="valid" lazy-validation>
                      <v-row>
                        <v-col cols="12" sm="6" class="my-0 py-0">
                          <v-text-field
                            id="nameField"
                            v-model="usuarioNovo.firstName"
                            label="Nome *"
                            dense
                            outlined
                            persistent-placeholder
                            :rules="[rules.nomeRules]"
                            @change="validData()"
                          />
                        </v-col>
                        <v-col cols="12" sm="6" class="my-0 py-0">
                          <v-text-field
                            id="lastNameField"
                            v-model="usuarioNovo.lastName"
                            label="Sobrenome *"
                            dense
                            outlined
                            persistent-placeholder
                            :rules="[rules.sobrenomeRules]"
                            @change="validData()"
                          />
                        </v-col>
                        <v-col cols="12" class="my-0 py-0">
                          <v-text-field
                            id="emailField"
                            v-model="usuarioNovo.emailAddress"
                            dense
                            label="Email *"
                            outlined
                            persistent-placeholder
                            :rules="[rules.emailRules]"
                            :error-messages="mailMessage"
                            @blur="existsEmail()"
                            @change="validData()"
                          />
                        </v-col>
                        <v-col cols="12" class="my-0 py-0">
                          <v-text-field
                            id="phoneField"
                            v-model="usuarioNovo.cellPhone"
                            v-mask="'(##) 9####-####'"
                            label="Celular *"
                            dense
                            outlined
                            persistent-placeholder
                            :rules="[rules.cellRules]"
                            placeholder="(00) 00000-0000"
                            @change="validData()"
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" class="my-0 py-0">
                          <v-text-field
                            id="userField"
                            v-model="usuarioNovo.username"
                            dense
                            label="Usuário *"
                            outlined
                            persistent-placeholder
                            :rules="[rules.usuarioRules, rules.usuario5Rules]"
                            placeholder="Min. 5 caracteres"
                            :error-messages="userMessage"
                            @blur="existsUsername()"
                            @change="validData()"
                          />
                        </v-col>
                        <v-col cols="12" sm="6" class="my-0 py-0">
                          <v-text-field
                            id="passwordField"
                            ref="passwordField"
                            v-model="usuarioNovo.password"
                            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                            hide-details="true"
                            dense
                            :type="show ? 'text' : 'password'"
                            :rules="[
                              rules.passwordRules,
                              rules.password8Rules,
                              rules.passwordNumberRules,
                              rules.passwordUpperCaseRules,
                              rules.passwordLowercaseCaseRules,
                              rules.passwordSpecialCharacterRules,
                            ]"
                            class="input-group--focused"
                            label="Senha *"
                            outlined
                            min="8"
                            persistent-placeholder
                            @click:append="show = !show"
                            @input="updatePassword"
                            @change="validData()"
                          />
                        </v-col>
                        <v-col cols="12" sm="6" class="my-0 py-0">
                          <v-text-field
                            id="passwordConfirmField"
                            v-model="usuarioNovo.confirmPassword"
                            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                            :rules="[rules.matchingPassword]"
                            :type="show ? 'text' : 'password'"
                            :error-messages="passwordMessage"
                            dense
                            class="input-group--focused"
                            label="Confirmar senha"
                            outlined
                            min="8"
                            persistent-placeholder
                            @click:append="show = !show"
                            @blur="matchPassword()"
                            @change="validData()"
                          />
                        </v-col>
                      </v-row>

                      <v-row v-if="showErrorPassword" style="position: relative; top: -23px">
                        <v-col cols="6" class="my-0 py-0">
                          <div
                            v-for="item in validations"
                            :id="item.id"
                            :key="item.id"
                            :class="{ 'password-validation-messages--error': !item.isValid }"
                            class="password-validation-messages"
                          >
                            <v-icon :id="item.id + '-button'" small class="password-validation-icons">
                              {{ !item.isValid ? 'mdi-close-circle-outline' : 'mdi-check-circle-outline' }}
                            </v-icon>
                            <span>{{ item.text }}</span>
                          </div>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-container>
                </v-card-text>
                <div class="text-left ml-8" style="font-size: 14px; color: #575767; font-weight: 400">
                  * Campos de preenchimento obrigatório
                </div>
                <v-card-actions>
                  <v-spacer></v-spacer>

                  <v-btn id="closebtnField" text @click="close"> Cancelar </v-btn>
                  <!-- <v-btn :disabled="!valid" color="success" @click="addUser" id="addbtnField"> Salvar </v-btn> -->
                  <v-btn :disabled="!valid" color="success" @click="addUser" id="addbtnField"> Salvar </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>

        <template v-slot:item.firstName="{ item }"> {{ item.firstName }} {{ item.lastName }} </template>
        <template v-slot:item.actions="{ item }">
          <AGTooltip tooltip-text="Editar">
            <v-btn id="btnEdit" icon color="primary" title="Editar" :to="`/cadastros-gerenciais/usuarios/${item.id}`">
              <v-icon small> mdi-pencil </v-icon>
            </v-btn>
          </AGTooltip>
        </template>
      </v-data-table>
    </v-card>
  </v-main>
</template>
<script>
import UserService from '@/services/userService.js';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
//API Trabalhadores
import defaultScreen from '@/assets/json/userDefault.json';
import { mapActions, mapState } from 'vuex';
import { eventBus } from '../../../main.js';

export default {
  name: 'Usuarios',
  components: {
    AGTooltip: () => import('../commons/AGTooltip.vue'),
  },

  data() {
    return {
      usuarioNovo: {
        emailAddress: '',
        cellPhone: '',
        username: '',
        firstName: '',
        lastName: '',
        password: '',
        confirmPassword: '',
        clientId: 'web-front-end',
        authorities: [],
        layoutMenu: defaultScreen,
        adminMenus:
          '[{"name":"adminMenu","value":true},{"name":"helpDesk","value":true},{"name":"profileScreen","value":true}]',
      },

      notValid: false,
      show: false,
      dialogUsuario: false,
      rules: {
        nomeRules: (v) => (!!v && /^[A-Za-z]+$/.test(v)) || 'Nome deve ser informado',
        sobrenomeRules: (v) => (!!v && /^[A-Za-z]+$/.test(v)) || 'Sobrenome deve ser informado',
        usuarioRules: (value) => !!value || 'Usuário deve ser informado',
        usuario5Rules: (value) => /^(?=.*[a-zA-Z]).{5,}$/.test(value) || 'Deve ter 5 caracteres',
        passwordRules: (value) => !!value || 'Preencha uma senha válida',
        password8Rules: (value) => this.isGreaterThan8AndLessThan20CharPassword(value) || 'De 8 a 20 caracteres',
        passwordNumberRules: (value) => this.hasANumberInPassword(value) || 'Um número de 0 a 9',
        passwordUpperCaseRules: (value) => this.hasAUpperWordInPassword(value) || 'Letra maiúscula',
        passwordLowercaseCaseRules: (value) => this.hasALowerWordInPassword(value) || 'Letra minúscula',
        passwordSpecialCharacterRules: (value) =>
          this.hasSpecialCharInPassword(value) || 'Um caracter especial "!@#$%"',

        emailRules: (v) =>
          /^[a-zA-Z].*(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v,
          ) || 'E-mail válido deve ser informado.',

        cellRules: (v) =>
          (!!v && /^\([1-9]{2}\) (?:[2-8]|9[1-9])[0-9]{3}\-[0-9]{4}$/.test(v)) ||
          'Preencha um número de celular válido',

      },

      userMessage: '',
      mailMessage: '',
      passwordMessage: '',
      valid: false,
      showErrorPassword: false,
      toast: {
        message: '',
        type: '',
        show: false,
        topToast: 0,
      },

      headers: [
        { text: 'Nome', align: 'start', value: 'firstName' },
        { text: 'E-mail', value: 'email' },
        { text: 'Usuário', value: 'username' },
        { text: 'Ações', value: 'actions', align: 'center', sortable: false, width: '10%' },
      ],

      usuarios: [],
    };
  },

  computed: {
    ...mapState('usuarios', {
      establishments: (state) => state.establishments,
      companies: (state) => state.companies,
      userEstablishments: (state) => state.userEstablishments,
      users: (state) => state.users,
      loading: (state) => state.loading,
      userRoles: (state) => state.userRoles,
      allRoles: (state) => state.allRoles,
    }),

    validations() {
      return [
        {
          id: 'sizeChar',
          text: 'De 8 a 20 caracteres',
          isValid: /^(?=.*[a-zA-Z\d]).{8,20}$/.test(this.usuarioNovo.password) === true,
        },
        {
          id: 'sizeNumber',
          text: 'Um número de 0 a 9',
          isValid: /^(?=.*[0-9])/.test(this.usuarioNovo.password) === true,
        },
        {
          id: 'upper',
          text: 'Letra maiúscula',
          isValid: /^(?=.*[A-Z])/.test(this.usuarioNovo.password) === true,
        },
        {
          id: 'lower',
          text: 'Letra minúscula',
          isValid: /^(?=.*[a-z])/.test(this.usuarioNovo.password) === true,
        },
        {
          id: 'specialChar',
          text: 'Um caracter especial "!@#$%"',
          isValid: /^(?=.*[@!#$%^&+=*()^{}~])/.test(this.usuarioNovo.password) === true,
        },
      ];
    },

    establishmentsParsed() {
      let items = [];
      let groupMatriz = groupBy(this.establishments, 'socialName');
      groupMatriz = Object.entries(groupMatriz).map(([key, value]) => ({
        company: key,
        establishments: value,
      }));

      groupMatriz = sortBy(groupMatriz, ['socialName']);
      groupMatriz.forEach((item, index) => {
        if (index > 0) items.push({ divider: true });
        const group = item.company;
        items.push({ header: group });
        item.establishments.forEach((innerItem) => {
          innerItem.group = group;
          items.push(innerItem);
        });
      });

      return items;
    },
  },

  created() {
    this.getUsers();
  },

  methods: {
    ...mapActions('usuarios', ['getUsers', 'saveUser']),

    validData() {
      let validEmail = false;
      let validFirstName = this.FirstName != '';
      let validLastName = this.lastName != '';
      let validPhone = this.cellPhone != '';
      let validUser = this.existsUsername;
      let validConfirmPassword = this.matchPassword;
      let rolesDefault = this.rules;

      this.existsEmail()
        .then((result) => {
          if (result == true) {
            this.validEmail = true;
          }
        })
        .catch((error) => {
          console.error(error);
        });

      if (
        validEmail &&
        validFirstName &&
        validLastName &&
        validPhone &&
        validUser &&
        validConfirmPassword &&
        rolesDefault
      ) {
        return true;
      } else {
        return false;
      }
    },

    async addUser() {
      if (this.validData() == true) {
        this.valid = true;
        return;
      } else {
        this.valid = false;
      }

      try {
        let user = this.usuarioNovo;
        user.username = user.username.toLowerCase();
        let passwordMatching = this.matchPassword();
        if (user.password.length >= 8 && passwordMatching) {
          user.layoutMenu = JSON.stringify(user.layoutMenu);
          this.saveUser(user);
          this.close();
          this.showToast(
            `Usuário adicionado com sucesso!
          O mesmo receberá um e-mail para continuar com o cadastro`,
            'success',
            78,
          );
        } else {
          this.notValid = true;
        }
      } catch (e) {
        this.showToast('Usuario não salvo! - ' + e, 'error', 78);
      }
    },

    close() {
      this.dialogUsuario = false;
      this.dialogEditEstablishment = false;
      this.dialogEditPermission = false;
      this.updatePassword('false');
      // this.rules.matchingPassword = true;
      // this.rules.passwordRules = true;
      // this.rules.password8Rules = true;
      // this.rules.passwordNumberRules = true;
      // this.rules.passwordUpperCaseRules = true;
      // this.rules.passwordLowercaseCaseRules = true;
      // this.rules.passwordSpecialCharacterRules = true;
      // this.validations = null;
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },

    existsUsername() {
      UserService.existsUsername(this.usuarioNovo.username)
        .then((response) => {
          if (response.data == false) {
            this.userMessage = '';
          } else {
            this.userMessage = 'Usuário já cadastrado';
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    matchPassword(value) {
      if (value) {
        this.passwordMessage = '';
        return true;
      } else {
        if (this.usuarioNovo.password === this.usuarioNovo.confirmPassword) {
          this.passwordMessage = '';
          this.rules.matchingPassword = true;
          return true;
        } else {
          this.passwordMessage = 'Senhas não coincidem';
          this.rules.matchingPassword = false;
          return false;
        }
      }
    },

    async existsEmail() {
      let exists = await UserService.existsEmail(this.usuarioNovo.emailAddress)
        .then((response) => {
          if (response.data === false) {
            this.mailMessage = '';
            return false;
          } else {
            this.mailMessage = 'E-mail já cadastrado';
            return true;
          }
        })
        .catch((err) => {
          console.log(err);
          return true;
        });
      return exists;
    },

    updatePassword(value) {
      if (value === 'false' || value === null) {
        this.showErrorPassword = false;
        this.matchPassword('false');
        this.usuarioNovo = {};
      } else {
        if (value) {
          const isValid = this.$refs.passwordField.validate();

          this.showErrorPassword = !isValid;
        }
        if (this.usuarioNovo.confirmPassword) this.matchPassword();
      }
    },

    isGreaterThan8AndLessThan20CharPassword(val) {
      return /^(?=.*[a-zA-Z\d]).{8,20}$/.test(val) === true;
    },

    hasANumberInPassword(val) {
      return /^(?=.*[0-9])/.test(val);
    },

    hasAUpperWordInPassword(val) {
      return /^(?=.*[A-Z])/.test(val) === true;
    },

    hasALowerWordInPassword(val) {
      return /^(?=.*[a-z])/.test(val) === true;
    },

    hasSpecialCharInPassword(val) {
      return /^(?=.*[@!#$%^&+=*()^{}~])/.test(val) === true;
    },

    showToast(message, type, topToast) {
      this.toast.show = true;
      this.toast.type = type;
      this.toast.message = message;
      this.toast.topToast = topToast;
      eventBus.$emit('openToast', this.toast);
    },
  },
};
</script>
<style scoped lang="scss">
@import '@/design/variables.scss';

.password-validation-messages {
  display: flex;
  gap: 8px;
  align-items: center;
  color: $feedback-color-success-medium;
  font-size: 12px;
  &--error {
    color: $feedback-color-error-medium;
  }
  .password-validation-icons {
    font-size: 14px;
    color: inherit;
  }
}

.green-button {
  color: #288569 !important;
}
.red-button {
  color: #962d33 !important;
}
</style>
