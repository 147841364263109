var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-main",
    [
      _c("h2", { staticClass: "mt-2 mb-8 titleColor--text" }, [
        _vm._v("Usuários"),
      ]),
      _c(
        "v-card",
        [
          _c("v-card-title"),
          _c("v-data-table", {
            staticClass: "elevation-1",
            attrs: {
              headers: _vm.headers,
              items: _vm.users,
              loading: _vm.loading,
              "footer-props": {
                "items-per-page-options": [10, 25, 50],
              },
              "sort-by": "empresa",
              "sort-desc": false,
            },
            scopedSlots: _vm._u([
              {
                key: "top",
                fn: function () {
                  return [
                    _c(
                      "v-toolbar",
                      { attrs: { flat: "", color: "backgroundSecond" } },
                      [
                        _c("v-spacer"),
                        _c(
                          "v-dialog",
                          {
                            attrs: { "max-width": "500px", persistent: "" },
                            scopedSlots: _vm._u([
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      "button",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass:
                                              "mb-2 v-btn v-size--default primary",
                                            staticStyle: {
                                              "z-index": "203 !important",
                                            },
                                            attrs: {
                                              id: "button_add_user",
                                              type: "button",
                                              role: "button",
                                              "aria-haspopup": "true",
                                              dense: "",
                                              "aria-expanded": "false",
                                            },
                                          },
                                          "button",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "v-btn__content" },
                                          [_vm._v(" Adicionar usuário ")]
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                            model: {
                              value: _vm.dialogUsuario,
                              callback: function ($$v) {
                                _vm.dialogUsuario = $$v
                              },
                              expression: "dialogUsuario",
                            },
                          },
                          [
                            _c(
                              "v-card",
                              [
                                _c("v-card-title", [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "text-h5",
                                      staticStyle: { color: "#182026" },
                                    },
                                    [_vm._v("Adicionar usuário")]
                                  ),
                                ]),
                                _c(
                                  "v-card-text",
                                  { staticClass: "pa-4" },
                                  [
                                    _c(
                                      "v-container",
                                      [
                                        _c(
                                          "v-form",
                                          {
                                            ref: "form",
                                            attrs: { "lazy-validation": "" },
                                            model: {
                                              value: _vm.valid,
                                              callback: function ($$v) {
                                                _vm.valid = $$v
                                              },
                                              expression: "valid",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-row",
                                              [
                                                _c(
                                                  "v-col",
                                                  {
                                                    staticClass: "my-0 py-0",
                                                    attrs: {
                                                      cols: "12",
                                                      sm: "6",
                                                    },
                                                  },
                                                  [
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        id: "nameField",
                                                        label: "Nome *",
                                                        dense: "",
                                                        outlined: "",
                                                        "persistent-placeholder":
                                                          "",
                                                        rules: [
                                                          _vm.rules.nomeRules,
                                                        ],
                                                      },
                                                      on: {
                                                        change: function (
                                                          $event
                                                        ) {
                                                          return _vm.validData()
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.usuarioNovo
                                                            .firstName,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.usuarioNovo,
                                                            "firstName",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "usuarioNovo.firstName",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-col",
                                                  {
                                                    staticClass: "my-0 py-0",
                                                    attrs: {
                                                      cols: "12",
                                                      sm: "6",
                                                    },
                                                  },
                                                  [
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        id: "lastNameField",
                                                        label: "Sobrenome *",
                                                        dense: "",
                                                        outlined: "",
                                                        "persistent-placeholder":
                                                          "",
                                                        rules: [
                                                          _vm.rules
                                                            .sobrenomeRules,
                                                        ],
                                                      },
                                                      on: {
                                                        change: function (
                                                          $event
                                                        ) {
                                                          return _vm.validData()
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.usuarioNovo
                                                            .lastName,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.usuarioNovo,
                                                            "lastName",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "usuarioNovo.lastName",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-col",
                                                  {
                                                    staticClass: "my-0 py-0",
                                                    attrs: { cols: "12" },
                                                  },
                                                  [
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        id: "emailField",
                                                        dense: "",
                                                        label: "Email *",
                                                        outlined: "",
                                                        "persistent-placeholder":
                                                          "",
                                                        rules: [
                                                          _vm.rules.emailRules,
                                                        ],
                                                        "error-messages":
                                                          _vm.mailMessage,
                                                      },
                                                      on: {
                                                        blur: function (
                                                          $event
                                                        ) {
                                                          return _vm.existsEmail()
                                                        },
                                                        change: function (
                                                          $event
                                                        ) {
                                                          return _vm.validData()
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.usuarioNovo
                                                            .emailAddress,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.usuarioNovo,
                                                            "emailAddress",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "usuarioNovo.emailAddress",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-col",
                                                  {
                                                    staticClass: "my-0 py-0",
                                                    attrs: { cols: "12" },
                                                  },
                                                  [
                                                    _c("v-text-field", {
                                                      directives: [
                                                        {
                                                          name: "mask",
                                                          rawName: "v-mask",
                                                          value:
                                                            "(##) 9####-####",
                                                          expression:
                                                            "'(##) 9####-####'",
                                                        },
                                                      ],
                                                      attrs: {
                                                        id: "phoneField",
                                                        label: "Celular *",
                                                        dense: "",
                                                        outlined: "",
                                                        "persistent-placeholder":
                                                          "",
                                                        rules: [
                                                          _vm.rules.cellRules,
                                                        ],
                                                        placeholder:
                                                          "(00) 00000-0000",
                                                      },
                                                      on: {
                                                        change: function (
                                                          $event
                                                        ) {
                                                          return _vm.validData()
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.usuarioNovo
                                                            .cellPhone,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.usuarioNovo,
                                                            "cellPhone",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "usuarioNovo.cellPhone",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-row",
                                              [
                                                _c(
                                                  "v-col",
                                                  {
                                                    staticClass: "my-0 py-0",
                                                    attrs: { cols: "12" },
                                                  },
                                                  [
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        id: "userField",
                                                        dense: "",
                                                        label: "Usuário *",
                                                        outlined: "",
                                                        "persistent-placeholder":
                                                          "",
                                                        rules: [
                                                          _vm.rules
                                                            .usuarioRules,
                                                          _vm.rules
                                                            .usuario5Rules,
                                                        ],
                                                        placeholder:
                                                          "Min. 5 caracteres",
                                                        "error-messages":
                                                          _vm.userMessage,
                                                      },
                                                      on: {
                                                        blur: function (
                                                          $event
                                                        ) {
                                                          return _vm.existsUsername()
                                                        },
                                                        change: function (
                                                          $event
                                                        ) {
                                                          return _vm.validData()
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.usuarioNovo
                                                            .username,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.usuarioNovo,
                                                            "username",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "usuarioNovo.username",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-col",
                                                  {
                                                    staticClass: "my-0 py-0",
                                                    attrs: {
                                                      cols: "12",
                                                      sm: "6",
                                                    },
                                                  },
                                                  [
                                                    _c("v-text-field", {
                                                      ref: "passwordField",
                                                      staticClass:
                                                        "input-group--focused",
                                                      attrs: {
                                                        id: "passwordField",
                                                        "append-icon": _vm.show
                                                          ? "mdi-eye"
                                                          : "mdi-eye-off",
                                                        "hide-details": "true",
                                                        dense: "",
                                                        type: _vm.show
                                                          ? "text"
                                                          : "password",
                                                        rules: [
                                                          _vm.rules
                                                            .passwordRules,
                                                          _vm.rules
                                                            .password8Rules,
                                                          _vm.rules
                                                            .passwordNumberRules,
                                                          _vm.rules
                                                            .passwordUpperCaseRules,
                                                          _vm.rules
                                                            .passwordLowercaseCaseRules,
                                                          _vm.rules
                                                            .passwordSpecialCharacterRules,
                                                        ],
                                                        label: "Senha *",
                                                        outlined: "",
                                                        min: "8",
                                                        "persistent-placeholder":
                                                          "",
                                                      },
                                                      on: {
                                                        "click:append":
                                                          function ($event) {
                                                            _vm.show = !_vm.show
                                                          },
                                                        input:
                                                          _vm.updatePassword,
                                                        change: function (
                                                          $event
                                                        ) {
                                                          return _vm.validData()
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.usuarioNovo
                                                            .password,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.usuarioNovo,
                                                            "password",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "usuarioNovo.password",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-col",
                                                  {
                                                    staticClass: "my-0 py-0",
                                                    attrs: {
                                                      cols: "12",
                                                      sm: "6",
                                                    },
                                                  },
                                                  [
                                                    _c("v-text-field", {
                                                      staticClass:
                                                        "input-group--focused",
                                                      attrs: {
                                                        id: "passwordConfirmField",
                                                        "append-icon": _vm.show
                                                          ? "mdi-eye"
                                                          : "mdi-eye-off",
                                                        rules: [
                                                          _vm.rules
                                                            .matchingPassword,
                                                        ],
                                                        type: _vm.show
                                                          ? "text"
                                                          : "password",
                                                        "error-messages":
                                                          _vm.passwordMessage,
                                                        dense: "",
                                                        label:
                                                          "Confirmar senha",
                                                        outlined: "",
                                                        min: "8",
                                                        "persistent-placeholder":
                                                          "",
                                                      },
                                                      on: {
                                                        "click:append":
                                                          function ($event) {
                                                            _vm.show = !_vm.show
                                                          },
                                                        blur: function (
                                                          $event
                                                        ) {
                                                          return _vm.matchPassword()
                                                        },
                                                        change: function (
                                                          $event
                                                        ) {
                                                          return _vm.validData()
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.usuarioNovo
                                                            .confirmPassword,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.usuarioNovo,
                                                            "confirmPassword",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "usuarioNovo.confirmPassword",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm.showErrorPassword
                                              ? _c(
                                                  "v-row",
                                                  {
                                                    staticStyle: {
                                                      position: "relative",
                                                      top: "-23px",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-col",
                                                      {
                                                        staticClass:
                                                          "my-0 py-0",
                                                        attrs: { cols: "6" },
                                                      },
                                                      _vm._l(
                                                        _vm.validations,
                                                        function (item) {
                                                          return _c(
                                                            "div",
                                                            {
                                                              key: item.id,
                                                              staticClass:
                                                                "password-validation-messages",
                                                              class: {
                                                                "password-validation-messages--error":
                                                                  !item.isValid,
                                                              },
                                                              attrs: {
                                                                id: item.id,
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  staticClass:
                                                                    "password-validation-icons",
                                                                  attrs: {
                                                                    id:
                                                                      item.id +
                                                                      "-button",
                                                                    small: "",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        !item.isValid
                                                                          ? "mdi-close-circle-outline"
                                                                          : "mdi-check-circle-outline"
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.text
                                                                  )
                                                                ),
                                                              ]),
                                                            ],
                                                            1
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "text-left ml-8",
                                    staticStyle: {
                                      "font-size": "14px",
                                      color: "#575767",
                                      "font-weight": "400",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " * Campos de preenchimento obrigatório "
                                    ),
                                  ]
                                ),
                                _c(
                                  "v-card-actions",
                                  [
                                    _c("v-spacer"),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          id: "closebtnField",
                                          text: "",
                                        },
                                        on: { click: _vm.close },
                                      },
                                      [_vm._v(" Cancelar ")]
                                    ),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          disabled: !_vm.valid,
                                          color: "success",
                                          id: "addbtnField",
                                        },
                                        on: { click: _vm.addUser },
                                      },
                                      [_vm._v(" Salvar ")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "item.firstName",
                fn: function ({ item }) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(item.firstName) +
                        " " +
                        _vm._s(item.lastName) +
                        " "
                    ),
                  ]
                },
              },
              {
                key: "item.actions",
                fn: function ({ item }) {
                  return [
                    _c(
                      "AGTooltip",
                      { attrs: { "tooltip-text": "Editar" } },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              id: "btnEdit",
                              icon: "",
                              color: "primary",
                              title: "Editar",
                              to: `/cadastros-gerenciais/usuarios/${item.id}`,
                            },
                          },
                          [
                            _c("v-icon", { attrs: { small: "" } }, [
                              _vm._v(" mdi-pencil "),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }